@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: Gilroy-Black;
  src: url("assets/Fonts/Gilroy-Regular.ttf");
}
@font-face {
  font-family: Gilroy-Black;
  src: url("assets/Fonts/Gilroy-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Gilroy-Black;
  src: url("assets/Fonts/Gilroy-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: Gilroy-Black;
  src: url("assets/Fonts/Gilroy-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Gilroy-Black;
  src: url("assets/Fonts/Gilroy-ExtraBold.ttf");
  font-weight: 900;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy-Black";
  font-weight: 400;
}

@layer utilities {
  ::-webkit-scrollbar {
    background: transparent;
    width: 8px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Gilroy-Black";
  font-weight: 400;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

.swiper-pagination-bullet{
    border-radius: 2px !important;
    height: 3px !important;
    width: 2rem !important;
    background-color: rgb(255, 255, 255,1) !important;
    margin: 0.2rem !important;
}
.swiper-pagination{
    margin-bottom: 1.5rem !important;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active{
    background-color: #fff !important;
}
@media only screen and (min-width: 768px) {
  .videoCarousel .swiper-pagination .swiper-pagination-bullet{
    display: none !important;
    margin-bottom: 0.5rem !important;
  }
}

@layer utilities {
  ::-webkit-scrollbar {
    background: transparent;
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(88, 88, 88, 0.5);
    border-radius: 20px;
    border: transparent;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(88, 88, 88, 1);
  }
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.custom-stroke {
  -webkit-text-stroke: 0.5px rgb(255, 255, 255);
}

@media screen and (min-width: 640px) {
  .custom-stroke {
    -webkit-text-stroke: 1.2px rgb(255, 255, 255);
  }
}
@media screen and (min-width: 768px) {
  .custom-stroke {
    -webkit-text-stroke: 2px rgb(255, 255, 255);
  }
}
